import React from 'react'

import ServiceTemplate from '../components/templates/Service'

const FORM = [{
  title: 'Profession',
  question: 'Quelle est votre profession ?',
  name: 'profession',
  type: 'select',
  options: [{
    label: 'Courtier',
    value: 'broker'
  }, {
    label: 'Assureur',
    value: 'insurance'
  }, {
    label: 'Chasseur immobilier',
    value: 'hunter'
  }, {
    label: 'Expert en travaux',
    value: 'work'
  }, {
    label: 'Promoteur immobilier',
    value: 'promoter'
  }, {
    label: 'Agent immobilier',
    value: 'agent'
  }, {
    label: 'Conseiller en gestion de patrimoine',
    value: 'investor'
  }]
}, {
  title: 'Zone d\'intervention',
  name: 'zone',
  question: 'Dans quelle région/ville exercez-vous votre profession ?',
  type: 'input',
  inputs: [{
    name: 'city',
    placeholder: 'Votre région'
  }]
}, {
  title: 'Coordonnées',
  name: 'contact',
  question: 'Veuillez indiquer vos coordonnées pour que notre responsable partenariat puisse prendre contact avec vous',
  type: 'input',
  inputs: [{
    name: 'email',
    type: 'email',
    placeholder: 'Email'
  }, {
    name: 'phone',
    type: 'tel',
    placeholder: 'N° de téléphone'
  }]
}]

const Partners = () => {
  const pageContext = {
    id: 'partners',
    title: 'Devenir partenaire',
    button: 'Ça m\'intéresse',
    headline: 'Il vous suffit de répondre à quelques questions afin que nous puissions identifier votre besoin. Notre responsable des partenariats prendra contact avec vous dans les plus brefs délais',
    description: 'Vous travaillez dans le secteur immobilier et souhaitez devenir partenaire de Folhomee ?',
    metaTitle: 'Devenir partenaire',
    metaDescription: 'Courtier, spécialiste de l\'immobilier neuf, chasseur immobilier, etc. Devenez partenaire de Folhomee pour développer votre activité.',
    form: FORM,
    formUrl: 'https://hooks.zapier.com/hooks/catch/2114713/on3z5rd/'
  }

  return (
    <ServiceTemplate pageContext={pageContext} />
  )
}

export default Partners
